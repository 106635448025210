import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { navigate } from "@reach/router"

import Input from "@material-ui/core/Input"
import InputAdornment from "@material-ui/core/InputAdornment"

import Search from "../icons/search"
import { addCommas } from "../../utils/str"

const styles = theme => ({
  root: {
    height: "300px",
    background: "#0D2149",
    color: "#FFFFFF",
  },

  container: {
    width: "calc(100% - 340px)",
    maxWidth: "1100px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",

    [theme.breakpoints.between("xs", "md")]: {
      width: "calc(100% - 150px)",
    },

    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 80px)",
    },
  },

  header: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "25px",
    color: "#FFFFFF",
    textAlign: "center",

    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "26px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },

  desc: {
    fontSize: "15px",
    marginBottom: "25px",
    color: "#FFFFFF",
    textAlign: "center",
    fontWeight: "400",

    [theme.breakpoints.down("xs")]: {
      fontSize: "13px",
    },
  },

  searchRoot: {
    maxWidth: "527px",
    width: "80%",
    height: "48px",
    alignItems: "center",
    border: "1px solid #FFFFFF14",
    borderRadius: "24px",
    background: "#FFFFFF14",
    padding: "0px 5px 0px 15px",
    "& path": {
      fill: "rgba(255,255,255,0.38)",
    },

    [theme.breakpoints.down("sm")]: {
      height: "42px",
    },
  },

  searchInput: {
    background: "none",
    border: "none",
    color: "#FFFFFF",
    fontSize: "15px",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px 12px",
  },

  searchFocused: {
    border: "1px solid #FFFFFF",
    "& path": {
      fill: "#FFFFFF",
    },
  },
})

class SearchSection extends React.Component {
  state = {
    keyword: "",
    isMobile: false,
  }

  handleChange = e => {
    this.setState({
      keyword: e.target.value,
    })
  }

  handleEnterKeyPressed = e => {
    if (e.keyCode === 13) {
      if (this.state.keyword) {
        navigate(`search?keyword=${this.state.keyword}`)
      }
    }
  }

  componentDidMount() {
    // index.cssで、windowサイズが430px未満であれば改行して表示する
    if (typeof window !== 'undefined' && window.innerWidth < 430) {
      this.setState({
        isMobile: true,
      })
    }
  }

  render() {
    const { classes, corporationsTotal, keymansTotal } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <h1 className={classes.header}>エンタープライズの決裁者情報を検索</h1>
          <h2 className={classes.desc}>
            {addCommas(corporationsTotal)}社{addCommas(keymansTotal)}
            人の決裁者情報を掲載。
            <span style={{ display: this.state.isMobile ? 'block' : 'inline' }}>デイリーで更新。</span>
          </h2>
          <Input
            type="search"
            placeholder="決裁者また会社を検索"
            value={this.state.keyword}
            onChange={this.handleChange}
            onKeyDown={this.handleEnterKeyPressed}
            disableUnderline={true}
            startAdornment={
              <InputAdornment position="start" style={{ margin: 0 }}>
                <Search />
              </InputAdornment>
            }
            classes={{
              root: classes.searchRoot,
              input: classes.searchInput,
              focused: classes.searchFocused,
            }}
          />
        </div>
      </div>
    )
  }
}

SearchSection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SearchSection)
