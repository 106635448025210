import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import * as actions from "../state/actions/keymans"
import { KEYMAN_PER_PAGE, CORP_PER_PAGE } from "../constants/constants"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Banner from "../components/common/banner"
import KeymanItem from "../components/common/keyman-item"
import OtherCorporations from "../components/common/other-corporations"
import MoreButton from "../components/common/more-button"
import Card from "../components/common/card"
import LinkToUpdate from "../components/common/link-to-update"
import SearchSection from "../components/common/search-section"

const mapStateToProps = (state, ownProps) => {
  return {
    keymans: state.home.keymans.toIndexedSeq().toArray(),
    corporations: state.home.corporations.toIndexedSeq().toArray(),
    isLoadingKeymans: state.home.isLoadingKeymans,
    isLoadingCorps: state.home.isLoadingCorps,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.home.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymansHome: (data, overwrite) =>
      dispatch(actions.getKeymansHome(data, overwrite)),
    getCorporations: (data, overwrite) =>
      dispatch(actions.getCorporations(data, overwrite)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
  }
}

const styles = theme => ({})

class IndexPage extends React.Component {
  componentDidMount() {
    this.props.getKeymansHome({
      paging: 1,
      per_page: KEYMAN_PER_PAGE,
      page: 1,
    })

    this.props.getCorporations({
      paging: 1,
      per_page: CORP_PER_PAGE,
      page: 1,
    })

    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  render() {
    const {
      isLoadingKeymans,
      isLoadingCorps,
      keymanCountYstd,
      corporationsTotal,
      keymansTotal,
      isInitialized
    } = this.props

    let keymans =
      (!isInitialized) ||
      (isLoadingKeymans && this.props.keymans.length === 0)
        ? Array.from(Array(KEYMAN_PER_PAGE))
        : this.props.keymans

    let corporations =
      (!isInitialized) ||
      (isLoadingCorps && this.props.corporations.length === 0)
        ? Array.from(Array(CORP_PER_PAGE))
        : this.props.corporations

    return (
      <Layout>
        <SEO
          title="決裁者データベース「キーマンレター」"
          useTitleTemplate={false}
          description={`大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
          canonical="https://keyman-db.smart-letter.com"
        />
        <Helmet>
          <script type="application/ld+json">
            {`{
              "@context" : "https://schema.org",
              "@type" : "WebSite",
              "name" : "決裁者データベース「キーマンレター」",
              "url" : "https://keyman-db.smart-letter.com",
              "potentialAction": {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://keyman-db.smart-letter.com/search?keyword={search_term_string}"
                },
                "query-input": {
                  "@type": "PropertyValueSpecification",
                  "valueName": "search_term_string",
                  "valueRequired": "http://schema.org/True"
                }
              }
            }`}
          </script>
        </Helmet>
        <SearchSection
          keymansTotal={keymansTotal}
          corporationsTotal={corporationsTotal}
        />
        <div className="container">
          <Banner style={{ margin: "50px 0px 80px 0px" }} />

          <div className="list-container">
            <div className="left">
              <h2 className="title">決裁者一覧</h2>

              <div className="list">
                {keymans.map((keyman, i) => (
                  <KeymanItem
                    item={keyman}
                    key={i}
                    showDepartment={true}
                    showIndustry={true}
                    showBorderBottom={i !== keymans.length - 1}
                  />
                ))}
              </div>

              <MoreButton url="/keymans" text="決裁者一覧へ" />
            </div>

            <div className="right">
              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />

              <OtherCorporations
                corporations={corporations}
              />
            </div>
          </div>
          <Banner />
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(IndexPage)
)
